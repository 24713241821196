import React, { useState, useEffect } from 'react';
import Footer from './Homefooter';
 
const Team = () => {
  const [teamMembers, setTeamMembers] = useState([]);
 
  useEffect(() => {
    // Mock data for team members
    const mockTeamMembers = [
      { name: 'Vijay Patel', designation: 'Co-Founder | SVP', imageSrc: 'Vijay.png', description: 'Some description for Vijay Patel.' }
    ];
    setTeamMembers(mockTeamMembers);
  }, []);
 
  const cssStyles = `
    /* Styles for team member boxes */
    .team-member {
      text-align: center;
      margin: auto; /* Center horizontally */
      margin-top: 50px; /* Adjust top margin as needed */
      width: calc(33.33% - 30px);
      box-sizing: border-box;
      background-color: hsl(0, 0%, 100%);
      padding: 15px;
      position: relative;
      overflow: hidden;
      border-radius: 15px;
      transition: transform 0.3s;
      display: flex;
      flex-direction: column;
      align-items: center; /* Center items horizontally */
    }
 
    /* Styles for team member images */
    .team-member img {
      width: 150px;
      height: 150px;
      border-radius: 50%; /* Adjust border-radius to make the image circular */
      object-fit: cover;
      margin-bottom: 10px; /* Add margin between image and name */
    }
 
    /* SeniorTeam.css */
    .background-image {
      background-image: url('founder.png');
      background-size: cover;
      background-position: center;
      height: 300px; /* Set the height as needed */
      width: 100%;
    }
  `;
 
  return (
    <>
      <style>{cssStyles}</style>
      <div className="background-image"></div>
      <div className="blank-page">
        <h1 style={{ textAlign: 'center', color: '#000000', background: 'none', padding: 0 }}>Leadership</h1>
        <p style={{ textAlign: 'center', color: '#000000' }}>
          <h5>The team at BitTwoBye believes in empowering teams to deliver their most impactful work, by building a culture
            of innovation & growth, where everyone can thrive.</h5>
        </p>
        <div className="team-members-wrapper">
          {teamMembers.map((member, index) => (
            <div key={index} className="team-member">
              <img src={member.imageSrc} alt={member.name} />
              <div className="team-member-info">
                <h3>{member.name}</h3>
                <p>{member.designation}</p>
              </div>
            </div>
          ))}
        </div>
        <Footer />
      </div>
    </>
  );
};
 
export default Team;
