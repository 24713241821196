import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

// Define keyframe for animation
const slideIn = keyframes`
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Styled components
const Container = styled.div`
  width: 100%;
  background-color: #f7f7f7; /* Updated background color */
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center align items vertically */
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Add subtle shadow */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }
`;

const TextContainer = styled.div`
  flex: 1;
`;

const Header = styled.div`
  margin-bottom: 24px; /* Add space below header */
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
  margin: 0;

  span {
    color: #14a514; /* Change color of span elements */
  }
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #0a0a0a;
  line-height: 1.6;
  margin: 12px 0;
  padding: 25px;
`;

const StatsSection = styled.div`
  display: flex;
  gap: 24px; /* Increased gap between stat items */
  justify-content: space-around; /* Center align on small screens */
  flex-wrap: wrap; /* Allow items to wrap */
`;

const StatItem = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px; /* Adjusted padding for smaller boxes */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: ${slideIn} 0.5s ease-out;

  @media (min-width: 768px) {
    width: calc(33.33% - 32px); /* Three items per row on larger screens */
  }

  @media (max-width: 767px) {
    padding: 16px; /* Further reduced padding for smaller screens */
    margin-bottom: 20px; /* Added margin to create space between items */
  }
`;

const Number = styled.p`
  font-size: 36px;
  font-weight: bold;
  margin: 0;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px; /* Adjust margin top as necessary */
`;

const Icon = styled.svg`
  width: 48px;
  height: 48px;
  fill: #d4ba2e; /* Change icon color to green */
  margin: 0 4px; /* Adjust margin between icons */
`;

const Label = styled.p`
  font-size: 16px;
  color: #333333;
`;

// Component definition
const OurNumber = () => {
  const [animateIcons, setAnimateIcons] = useState(false);

  useEffect(() => {
    setAnimateIcons(true); // Trigger animation when component mounts
  }, []);

  const [pointerPosition, setPointerPosition] = useState(0);

  useEffect(() => {
    const animatePointer = () => {
      const newPointerPosition = (pointerPosition + 1) % 101; // Adjusted to move from 0 to 100 inclusive
      setPointerPosition(newPointerPosition);
    };

    const interval = setInterval(animatePointer, 50); // Adjusted interval for smoother animation (50 milliseconds)
    return () => clearInterval(interval);
  }, [pointerPosition]);

  const sectionContainerStyle = {
    border: '1px solid #ccc',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  };

  const barStyle = {
    height: '5px', // Increased height for better visibility
    width: '80%', // Adjusted width of the bar
    background: '#cdf1d8',
    margin: '20px auto',
    position: 'relative',
    borderRadius: '30px',
    overflow: 'hidden',
  };

  const pointerStyle = {
    width: '10px', // Adjusted pointer width
    height: '100%',
    background: '#288939',
    position: 'absolute',
    top: '0',
    left: `${pointerPosition}%`,
    transition: 'left 0.05s ease-in-out', // Smooth transition for pointer movement
  };

  return (
    <div style={sectionContainerStyle}>
      <div className="section-title">
        <h2>Our Numbers</h2>
        <div className="bar" style={barStyle}>
          <div className="pointer" style={pointerStyle}></div>
        </div>
      </div>
      <Container>
        <TextContainer>
          <Header>
            <Title>
              Discover how <span>effective</span> <br /> our <span>services</span> are
            </Title>
            <Subtitle>
              What is success? It is the feeling of excitement about your business growth, creating a project you can feel proud of.
              The same sensations customers have when they work with Solvd and get desired results.
            </Subtitle>
          </Header>
        </TextContainer>
        <StatsSection>
          <StatItem>
            <Number>20+</Number>
            <IconContainer>
              {animateIcons && (
                <>
                  <Icon viewBox="0 0 10 10">
                    <path d="M5 0L6.41656 3.05027L9.75528 3.45492L7.29205 5.74473L7.93893 9.04508L5 7.41L2.06107 9.04508L2.70795 5.74473L0.244718 3.45492L3.58344 3.05027L5 0Z" />
                  </Icon>
                  <Icon viewBox="0 0 10 10">
                    <path d="M5 0L6.41656 3.05027L9.75528 3.45492L7.29205 5.74473L7.93893 9.04508L5 7.41L2.06107 9.04508L2.70795 5.74473L0.244718 3.45492L3.58344 3.05027L5 0Z" />
                  </Icon>
                  <Icon viewBox="0 0 10 10">
                    <path d="M5 0L6.41656 3.05027L9.75528 3.45492L7.29205 5.74473L7.93893 9.04508L5 7.41L2.06107 9.04508L2.70795 5.74473L0.244718 3.45492L3.58344 3.05027L5 0Z" />
                  </Icon>
                  <Icon viewBox="0 0 10 10">
                    <path d="M5 0L6.41656 3.05027L9.75528 3.45492L7.29205 5.74473L7.93893 9.04508L5 7.41L2.06107 9.04508L2.70795 5.74473L0.244718 3.45492L3.58344 3.05027L5 0Z" />
                  </Icon>
                  <Icon viewBox="0 0 10 10">
                    <path d="M5 0L6.41656 3.05027L9.75528 3.45492L7.29205 5.74473L7.93893 9.04508L5 7.41L2.06107 9.04508L2.70795 5.74473L0.244718 3.45492L3.58344 3.05027L5 0Z" />
                  </Icon>
                </>
              )}
            </IconContainer>
            <Label>Completed projects</Label>
          </StatItem>
          <StatItem>
            <Number>10+</Number>
            <IconContainer>
              {animateIcons && (
                <>
                  <Icon viewBox="0 0 10 10">
                    <path d="M5 0L6.41656 3.05027L9.75528 3.45492L7.29205 5.74473L7.93893 9.04508L5 7.41L2.06107 9.04508L2.70795 5.74473L0.244718 3.45492L3.58344 3.05027L5 0Z" />
                  </Icon>
                  <Icon viewBox="0 0 10 10">
                    <path d="M5 0L6.41656 3.05027L9.75528 3.45492L7.29205 5.74473L7.93893 9.04508L5 7.41L2.06107 9.04508L2.70795 5.74473L0.244718 3.45492L3.58344 3.05027L5 0Z" />
                  </Icon>
                  <Icon viewBox="0 0 10 10">
                    <path d="M5 0L6.41656 3.05027L9.75528 3.45492L7.29205 5.74473L7.93893 9.04508L5 7.41L2.06107 9.04508L2.70795 5.74473L0.244718 3.45492L3.58344 3.05027L5 0Z" />
                  </Icon>
                  <Icon viewBox="0 0 10 10">
                    <path d="M5 0L6.41656 3.05027L9.75528 3.45492L7.29205 5.74473L7.93893 9.04508L5 7.41L2.06107 9.04508L2.70795 5.74473L0.244718 3.45492L3.58344 3.05027L5 0Z" />
                  </Icon>
                  <Icon viewBox="0 0 10 10">
                    <path d="M5 0L6.41656 3.05027L9.75528 3.45492L7.29205 5.74473L7.93893 9.04508L5 7.41L2.06107 9.04508L2.70795 5.74473L0.244718 3.45492L3.58344 3.05027L5 0Z" />
                  </Icon>
                </>
              )}
            </IconContainer>
            <Label>Clutch reviews</Label>
          </StatItem>
          <StatItem>
            <Number>3</Number>
            <IconContainer>
              {animateIcons && (
                <>
                  <Icon viewBox="0 0 10 10">
                    <path d="M5 0L6.41656 3.05027L9.75528 3.45492L7.29205 5.74473L7.93893 9.04508L5 7.41L2.06107 9.04508L2.70795 5.74473L0.244718 3.45492L3.58344 3.05027L5 0Z" />
                  </Icon>
                  <Icon viewBox="0 0 10 10">
                    <path d="M5 0L6.41656 3.05027L9.75528 3.45492L7.29205 5.74473L7.93893 9.04508L5 7.41L2.06107 9.04508L2.70795 5.74473L0.244718 3.45492L3.58344 3.05027L5 0Z" />
                  </Icon>
                  <Icon viewBox="0 0 10 10">
                    <path d="M5 0L6.41656 3.05027L9.75528 3.45492L7.29205 5.74473L7.93893 9.04508L5 7.41L2.06107 9.04508L2.70795 5.74473L0.244718 3.45492L3.58344 3.05027L5 0Z" />
                  </Icon>
                  <Icon viewBox="0 0 10 10">
                    <path d="M5 0L6.41656 3.05027L9.75528 3.45492L7.29205 5.74473L7.93893 9.04508L5 7.41L2.06107 9.04508L2.70795 5.74473L0.244718 3.45492L3.58344 3.05027L5 0Z" />
                  </Icon>
                  <Icon viewBox="0 0 10 10">
                    <path d="M5 0L6.41656 3.05027L9.75528 3.45492L7.29205 5.74473L7.93893 9.04508L5 7.41L2.06107 9.04508L2.70795 5.74473L0.244718 3.45492L3.58344 3.05027L5 0Z" />
                  </Icon>
                </>
              )}
            </IconContainer>
            <Label>Years in business</Label>
          </StatItem>
        </StatsSection>
      </Container>
    </div>
  );
};

export default OurNumber;
