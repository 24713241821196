import React, { useState } from 'react';
import Footer from './Homefooter';

const OurBlog = () => {
  
  const [hovered, setHovered] = useState(false);
  const [hoveredBox] = useState('');
  const [clickedBox] = useState('');


 
  const containerStyle = {
    maxWidth: '370px',
    marginRight: '20px',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    marginBottom: '20px',
    transition: 'box-shadow 0.3s ease',
    zIndex: 1,
    margin: '10px',
    width: '300px',
    height: '400px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer',
  };

  const hoverTextStyle = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    opacity: clickedBox === hoveredBox ? 1 : 0,
    transition: 'opacity 0.3s ease, transform 0.3s ease',
    transform: `translateY(${clickedBox === hoveredBox ? '0' : '-100%'})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    padding: '20px',
    borderRadius: '10px',
    zIndex: 10,
    overflowY: 'scroll',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: 'white',
    textAlign: 'center',
    padding: '10px',
    boxSizing: 'border-box',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: hoveredBox === null || hoveredBox === clickedBox ? 1 : 0,
    transition: 'opacity 0.3s ease',
    zIndex: 1,
  };

  const hoverTextContentStyle = {
   fontSize: '14px',
    fontWeight: 400,
    textAlign: 'left',
  };

  const imageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    opacity: hoveredBox === clickedBox ? 1 : 0,
    transition: 'opacity 0.3s ease',
    zIndex: 0,
  };
  return (
    <div style={{ fontSize: 'calc(1px + 2vmin)', backgroundColor: 'white' }}>
        <div
  style={{
    position: 'relative',
    height: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: 'calc(12px + 3vmin)',
    textAlign: 'center',
    color: 'white', // Text color changed to white for better contrast
    backgroundImage: "url('Partner.png')",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '10px', // Rounded corners for the container
    overflow: 'hidden', // Ensure content within the container doesn't overflow
  }}
>
  {/* Dark overlay */}
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // Darkened overlay color (0.7 opacity)
      zIndex: 1, // Overlay is above the background image
    }}
  />
  {/* Text over the image */}
  <h1
    style={{
      position: 'relative', // Relative positioning to respect parent's z-index
      zIndex: 2, // Text is above the overlay
      marginTop: '20px', // Adjust vertical spacing as needed
    }}
  >
    Our Partnership
  </h1>
</div>

  



      {/* Text section */}
      <div
        style={{
          marginTop: '30px', padding: '0 10px', fontSize: '19px',marginLeft: '20px', fontStyle: 'normal' 
        }}
      >
        <h4>
        BitTwoByte believes in fostering strong partnerships that drive mutual success and innovation. Our collaborative approach focuses on leveraging technology to create impactful solutions that propel businesses forward.
        </h4>
      </div>

      {/* Databricks section */}
      <div
        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e20126a"
        data-id="e20126a"
        data-element_type="column"
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-7d0e0f4 elementor-widget elementor-widget-text-editor"
            data-id="7d0e0f4"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container" style={{ textAlign: 'center', padding: '30px' }}>
              <h1 style={{ fontSize: '30px' }}>Why Databricks?</h1>
              <span style={{ fontSize: '25px' }}>Databricks helps unify all your data and AI.</span>
            </div>
          </div>
        </div>
      </div>

        {/* Boxes section */}
        <div style={{ fontSize: 'calc(1px + 2vmin)', backgroundColor: 'white' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginBottom: '20px',
          flexWrap: 'wrap',
        }}
      >
        {/* Box 1 */}
        <div
          style={containerStyle}
      
        
        >
          {/* Hover Text */}
          <div className="hoverText" style={hoverTextStyle}>
            <h3>AI</h3>
            <p style={hoverTextContentStyle}>
              Build better AI with a data-centric approach. Databricks helps maintain lineage, quality, control, and data privacy across the entire AI workflow, powering a complete set of tools to deliver any AI use case.
            </p>
            
          </div>
          {/* Image */}
          <img
            loading="lazy"
            decoding="async"
            width="100%"
            height="100%"
            src="AI.png"
            alt="AI"
            style={imageStyle}
          />
        </div>

        {/* Box 2 */}
        <div
          style={containerStyle}
  
         
        >
          {/* Hover Text */}
          <div className="hoverText" style={hoverTextStyle}>
            <h3>Warehouses</h3>
            <p style={hoverTextContentStyle}>
              Databricks’ Lakehouse offers 12x better price/performance for SQL and BI workloads. It is serverless for simplified management.
            </p>
           
          </div>
          {/* Image */}
          <img
            loading="lazy"
            decoding="async"
            width="100%"
            height="100%"
            src="Warehouses.png"
            alt="Warehouses"
            style={imageStyle}
          />
        </div>

        {/* Box 3 */}
        <div
          style={containerStyle}
      
       
        >
          {/* Hover Text */}
          <div className="hoverText" style={hoverTextStyle}>
            <h3>ETL</h3>
            <p style={hoverTextContentStyle}>
              Intelligent data processing for batch and real-time. You can implement a single solution for all ETL/ELT use cases that automatically adapts to help ensure data quality.
            </p>
            
          </div>
          {/* Image */}
          <img
            loading="lazy"
            decoding="async"
            width="100%"
            height="100%"
            src="ETL.jpg"
            alt="ETL"
            style={imageStyle}
          />
        </div>

        {/* Box 4 */}
        <div
          style={containerStyle}
         
          
        >
          {/* Hover Text */}
          <div className="hoverText" style={hoverTextStyle}>
            <h3>Governance</h3>
            <p style={hoverTextContentStyle}>
              Databricks unifies governance for data, analytics, and AI for a compliant, end-to-end view of your data estate. It offers a single data governance model for all structured and unstructured data.
            </p>
           
          </div>
          {/* Image */}
          <img
            loading="lazy"
            decoding="async"
            width="100%"
            height="100%"
            src="Governanace.jpg"
            alt="Governance"
            style={imageStyle}
          />
        </div>

      </div>
    </div>

      <div className="container-fluid"
  style={{
    position: 'relative',
    zIndex: '1',
    background: 'linear-gradient(135deg, #012353 , #43e794)',
    paddingTop: '50px',
    paddingBottom: '50px',
    paddingLeft: '20px',
    paddingRight: '20px',
  }}
>
  <div className="row align-items-center"
    style={{
      display: 'flex',
      flexWrap: 'wrap',
      '--bs-gutter-x': '1.6rem',
      '--bs-gutter-y': '0.5',
      marginTop: 'calc(-1 * var(--bs-gutter-y))',
      marginRight: 'calc(-.5 * var(--bs-gutter-x))',
      marginLeft: 'calc(-.5 * var(--bs-gutter-x))',
    }}
  >
    <div className="col-lg-6 col-md-12">
      <div className="discover-image"
        style={{
          position: 'relative',
          borderRadius: '10px',
          overflow: 'hidden',
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)',
          transition: 'transform 0.3s ease',
          transform: hovered ? 'translateY(-10px)' : 'translateY(0)',
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <img
          alt="image"
          loading="lazy"
          width="100%"
          height="auto"
          decoding="async"
          data-nimg="1"
          style={{
            objectFit: 'cover',
            borderRadius: '10px', // Rounded corners
            filter: 'brightness(80%)', // Adjust brightness for blending
            mixBlendMode: 'multiply', // Blend mode to blend with background
          }}
          src="Partner.png" // Replace with your image source
        />
        {/* Gradient overlay */}
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundImage:
              'linear-gradient(to bottom right, rgba(1, 35, 83, 0.8), rgba(67, 231, 148, 0.5))',
            borderRadius: '10px', // Rounded corners
            opacity: '0.7', // Adjust overlay opacity
          }}
        />
      </div>
    </div>
    <div className="col-lg-6 col-md-12">
      <div className="discover-content">
        <h2 style={{ color: '#ffffff', fontSize: '2.5rem', marginBottom: '20px' }}>
          Simplify Your Data Estate with Open, Scalable Lakehouse Architecture
        </h2>
        <p style={{ color: '#ffffff', fontSize: '1.1rem', lineHeight: '1.6' }}>
          The Databricks Data Intelligence Platform utilizes lakehouse architecture—a unified, open, and scalable system that merges the best features of data lakes and data warehouses. As a Databricks implementation partner, we take this approach to enable organizations to reduce costs and accelerate their data and AI initiatives. Built on open source and open standards, the lakehouse architecture effectively eliminates data silos, streamlining your data estate and simplifying the integration and analysis of data for AI applications. bittowbyte Databricks consulting services help you implement the best features of the Lakehouse architecture to take your data management to the next level.
        </p>
      </div>
    </div>
    {/* Text over the image */}
  </div>
</div>



<div style={{ textAlign: 'center', marginTop: '50px', marginBottom: '70px' }}>
      <h2 style={{ fontSize: '2em', marginBottom: '20px' }}>Explore Our Services</h2>
      <p style={{ fontSize: '1.2em', marginBottom: '40px' }}>
        Discover how we can help you achieve your goals through collaboration, innovation, and growth.
      </p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '30px',
          padding: '20px',
          backgroundColor: '#F2F3F4',
        }}
      >
        {/* Collaboration */}
        <div
          className="icon-container"
          style={{
            width: 'calc(33.33% - 30px)',
            height: '350px',  // Increased height here
            borderRadius: '10px',
            overflow: 'hidden',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
            backgroundColor: '#ffffff',
            position: 'relative',
            transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-5px)';
            e.currentTarget.style.boxShadow = '0 12px 20px rgba(0, 0, 0, 0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(0)';
            e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
          }}
        >
          <div
            style={{
              padding: '10px',
              borderBottom: '3px solid #667292',
              background: '#8d9db6',
              color: '#ffffff',
              textAlign: 'center',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
            }}
          >
            <img
              src="collaboration-image.jpg"
              alt="Collaboration"
              style={{
                width: '100%',
                height: '160px', // Adjusted height of the image
                objectFit: 'cover',
                marginBottom: '10px',
                transition: 'transform 0.3s ease-in-out',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.05)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
              }}
            />
            <h3 style={{ fontSize: '1.2em', marginBottom: '5px', fontWeight: 'bold' }}>Collaboration</h3>
            <p style={{ fontSize: '1em', lineHeight: '1.4' }}>
              Explore collaborative opportunities with BitTwoByte. Together, we can achieve more through shared expertise
              and innovation.
            </p>
          </div>
        </div>

        {/* Innovation */}
        <div
          className="icon-container"
          style={{
            width: 'calc(33.33% - 30px)',
            height: '350px',  // Increased height here
            borderRadius: '10px',
            overflow: 'hidden',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
            backgroundColor: '#ffffff',
            position: 'relative',
            transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-5px)';
            e.currentTarget.style.boxShadow = '0 12px 20px rgba(0, 0, 0, 0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(0)';
            e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
          }}
        >
          <div
            style={{
              padding: '10px',
              borderBottom: '3px solid #667292',
              background: '#8d9db6',
              color: '#ffffff',
              textAlign: 'center',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
            }}
          >
            <img
              src="innovation-image.jpg"
              alt="Innovation"
              style={{
                width: '100%',
                height: '160px', // Adjusted height of the image
                objectFit: 'cover',
                marginBottom: '10px',
                transition: 'transform 0.3s ease-in-out',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.05)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
              }}
            />
            <h3 style={{ fontSize: '1.2em', marginBottom: '5px', fontWeight: 'bold' }}>Innovation</h3>
            <p style={{ fontSize: '1em', lineHeight: '1.4' }}>
              Innovate with cutting-edge technology solutions. Our team is dedicated to pushing boundaries and delivering
              transformative results.
            </p>
          </div>
        </div>

        {/* Growth */}
        <div
          className="icon-container"
          style={{
            width: 'calc(33.33% - 30px)',
            height: '350px',  // Increased height here
            borderRadius: '10px',
            overflow: 'hidden',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
            backgroundColor: '#ffffff',
            position: 'relative',
            transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-5px)';
            e.currentTarget.style.boxShadow = '0 12px 20px rgba(0, 0, 0, 0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(0)';
            e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.1)';
          }}
        >
          <div
            style={{
              padding: '10px',
              borderBottom: '3px solid #667292',
              background: '#8d9db6',
              color: '#ffffff',
              textAlign: 'center',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
            }}
          >
            <img
              src="growth-image.jpg"
              alt="Growth"
              style={{
                width: '100%',
                height: '160px', // Adjusted height of the image
                objectFit: 'cover',
                marginBottom: '10px',
                transition: 'transform 0.3s ease-in-out',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.05)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
              }}
            />
            <h3 style={{ fontSize: '1.2em', marginBottom: '5px', fontWeight: 'bold' }}>Growth</h3>
            <p style={{ fontSize: '1em', lineHeight: '1.4' }}>
              Drive sustainable growth with our tailored solutions and strategic insights. Together, we can achieve
              long-term success and scalability.
            </p>
          </div>
        </div>
      </div>
    </div>
<section
      className="elementor-section elementor-top-section elementor-element elementor-element-4d01f42 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="4d01f42"
      data-element_type="section"
      style={{
        position: 'relative',
        backgroundImage: 'url("Contactus.jpg")', // Background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '200px', // Adjust height as needed
        marginTop: '50px', // Add top margin for space
        marginBottom: '0px', // Add bottom margin for space
      }}
    >
      {/* Background overlay */}
      <div
        className="elementor-background-overlay"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
        }}
      ></div>

      {/* Container */}
      <div
        className="elementor-container elementor-column-gap-default"
        style={{ position: 'relative', zIndex: 1, paddingTop: '100px', paddingBottom: '100px' }}
      >
        {/* First Text Block */}
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-055373f"
          data-id="055373f"
          data-element_type="column"
          style={{ textAlign: 'left', color: '#fff', marginLeft: '5%', marginRight: '15%', marginBottom: '20px' }} // Adjusted marginLeft, marginRight, and added marginBottom
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-daac3b2 elementor-widget elementor-widget-heading"
              data-id="daac3b2"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h1 className="elementor-heading-title elementor-size-default">
                  Start Your Databricks Journey with BitTwoByte!
                </h1>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-91b3b9b elementor-widget elementor-widget-text-editor"
              data-id="91b3b9b"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                <h3><p>
                  Ready to harness the full power of your data? Schedule a Databricks consultation
                  with BitTwoByte today!
                </p></h3>
              </div>
            </div>
          </div>
        </div>
        
        {/* Second Text Block (Button) */}
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-aae630e"
          data-id="aae630e"
          data-element_type="column"
          style={{ textAlign: 'right', marginRight: '15%' }} // Adjusted marginRight
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-026a8ae elementor-align-right elementor-widget elementor-widget-button"
              data-id="026a8ae"
              data-element_type="widget"
              data-widget_type="button.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-button-wrapper">
                  <a
                    className="elementor-button elementor-button-link elementor-size-md"
                    href="/Contact" 
                    style={{
                      color: '#fff',
                      background: 'linear-gradient(135deg, #012353, #43e794)',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      textDecoration: 'none',
                      display: 'inline-block',
                    }}
                  >
                    <span className="elementor-button-content-wrapper">
                      <span className="elementor-button-text">Connect with us</span>
                      <span className="elementor-button-icon elementor-align-icon-right">
                        <i aria-hidden="true" className="fas fa-long-arrow-alt-right"></i>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
{/* Footer */}
 <Footer />
      </div>
  );
};

export default OurBlog;


