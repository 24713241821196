import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Partner = () => {
    const [pointerPosition, setPointerPosition] = useState(0);
    const [hoveredImage, setHoveredImage] = useState(null);

    // Framer Motion animation controls
    const controls = useAnimation();
    const { ref: sliderRef, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

    useEffect(() => {
        const animatePointer = () => {
            setPointerPosition(prevPosition => (prevPosition + 1) % 101);
        };

        const interval = setInterval(animatePointer, 50);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (inView) {
            controls.start({ opacity: 1, x: 0 });
        } else {
            controls.start({ opacity: 0, x: -30 });
        }
    }, [inView, controls]);

    const handleMouseEnter = (image) => {
        setHoveredImage(image);
    };

    const handleMouseLeave = () => {
        setHoveredImage(null);
    };

    // Framer Motion variants for image animation
    const imageVariants = {
        initial: { y: 0, scale: 1 },
        hover: { y: -10, scale: 1.1 },
        bounce: {
            y: [10, -10],
            transition: {
                duration: 2,
                ease: 'linear',
                repeat: Infinity,
                repeatType: 'reverse'
            }
        }
    };

    return (
        <div style={sectionContainerStyle}>
            <div className="section-title">
                <h2>Our Partners</h2>
                <div style={barStyle}>
                    <motion.div
                        style={pointerStyle}
                        animate={{ left: `${pointerPosition}%` }}
                        transition={{ duration: 0.05, ease: "easeInOut" }}
                    />
                </div>
            </div>
            <div style={imageContainerStyle}>
                <motion.div
                    ref={sliderRef}
                    animate={controls}
                    style={imageSliderStyle}
                >
                    <motion.a
                        href="https://www.microsoft.com/en-in/microsoft-cloud"
                        target="_blank"
                        rel="noopener noreferrer"
                        onMouseEnter={() => handleMouseEnter('microsoft')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <motion.img
                            src="microsoft-logo.png"
                            alt="Microsoft"
                            variants={imageVariants}
                            initial="initial"
                            whileHover="hover"
                            animate="bounce"
                            style={{
                                ...imageStyle,
                                marginTop: '1px',
                            }}
                            transition={{ duration: 0.3 }}
                        />
                    </motion.a>
                    <motion.a
                        href="https://www.databricks.com/company/partners/consulting-and-si"
                        target="_blank"
                        rel="noopener noreferrer"
                        onMouseEnter={() => handleMouseEnter('databricks')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <motion.img
                            src="databricks-image.png"
                            alt="Databricks"
                            variants={imageVariants}
                            initial="initial"
                            whileHover="hover"
                            animate="bounce"
                            style={{
                                ...imageStyle,
                                marginTop: '14px',
                            }}
                            transition={{ duration: 0.3 }}
                        />
                    </motion.a>
                </motion.div>
            </div>
        </div>
    );
};

// Inline styles
const sectionContainerStyle = {
    border: '1px solid #ccc',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    overflow: 'hidden',
};

const barStyle = {
    height: '5px',
    width: '80%',
    background: '#cdf1d8',
    margin: '20px auto',
    position: 'relative',
    borderRadius: '30px',
    overflow: 'hidden',
};

const pointerStyle = {
    width: '10px',
    height: '100%',
    background: '#288939',
    position: 'absolute',
    top: '0',
};

const imageContainerStyle = {
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
};

const imageSliderStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: '30px',
    whiteSpace: 'nowrap',
    alignItems: 'center',
};

const imageStyle = {
    width: '150px',
    height: 'auto',
    borderRadius: '10px',
    transition: 'transform 0.3s ease',
};

export default Partner;
